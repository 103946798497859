<template>
<div></div>
  <!-- <div class="col-12 flex-column d-flex justify-content-center align-items-center col-lg-5 order-1 order-md-2 px-0 pt-1 pb-4" style="">
    <div class="btn-group">
      <button @click="meusContratos" class="btn justify-content-left text-decoration-none d-flex flex-column align-items-center btn-link">
        <i class="fa fa-edit fa-fw"></i>contratos
      </button>
      <button @click="verAcordos" class="btn btn-link d-flex flex-column justify-content-left text-decoration-none align-items-center">
        <i class="fa fa-fw fa-file-text-o"></i>acordos</button>
        <button @click="cadastro" class="btn btn-link d-flex flex-column justify-content-left text-decoration-none align-items-center">
          <i class="fa fa-fw fa-user-o"></i>cadastro</button>
          <button @click="cadastroCodigo" class="btn d-flex flex-column justify-content-left text-decoration-none align-items-center btn-link">
            <i class="fa fa-fw fa-key"></i>código
          </button>
    </div>
  </div> -->
</template>

<script>
  export default {
    name: "HomeOptions",
    data () {
      return {}
    },
    methods: {
      meusContratos() {
        this.$router.push({
          name: "dashboard"
        });
      },
      verAcordos() {
        this.$router.push({
          name: "meusAcordos"
        });
      },
      cadastro() {
        this.$router.push({
          name: "cadastro"
        });
      },

      cadastroCodigo() {
        this.$router.push({
          name: "cadastro"
        });
      },
    }
  }
</script>
