<template>
  <div id="inside">
    <div class="container">
      <div class="row">
        <div class="col-12 text-body px-2 mb-2">
          <button class="btn justify-content-left text-decoration-none btn-link flex-row d-flex align-items-center pr-2 pb-2 pl-1" @click="home"><i class="fa fa-chevron-left fa-fw pull-left text-info"></i></button>
        </div>
      </div>

      <div class="row" v-if="billsInfo && !loadBoletos">
        <div class="col-12 text-body pr-0">
          <h4 class="mb-1 text-primary d-flex flex-row align-items-center">{{ isEmitirFatura ? 'Emitir fatura' : 'Boletos' }}</h4>
        </div>
        <HeaderOptions/>
      </div>
    </div>
    <ProgressLoading v-bind:loading="loadBoletos"></ProgressLoading>
    <div class="container" v-if="!billsInfo && !loadBoletos">
          <h4 class="mb-1 text-danger d-flex flex-row align-items-center">Não foi possível apresentar dados da emissão de fatura.</h4>
    </div>
    <div class="container" v-if="billsInfo && !loadBoletos">
      <div class="row">
        <div class="d-inline-flex flex-wrap flex-column col-acao mt-0 pt-0 col-lg-9">
          <div class="card m-1">
            <div class="card-header">
              <div class="row">
                <div class="col-12">
                  <h5>{{ billsInfo.product }}</h5>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12 col-12">
                  <h6 class="border-bottom pb-3 mb-0" v-if="isEmitirFatura">Boleto</h6>
                  <h6 class="border-bottom pb-3 mb-0" v-if="!isEmitirFatura">Boletos</h6>
                  <div class="accordion" id="boleto">
                    <div class="card m-0 px-0 py-0" v-for="(installment, key, index) in billsInfo.installments" :key="`${key}-${index}`">
                      <div class="card-header p-2 pb-3" id="head1">
                        <div class="row mt-4">
                          <div class="col-lg-3 col-5">
                            <p class="mb-1 small">Parcela:</p>
                            <h6 class="text-body" v-if="isEmitirFatura">{{ (key+1) + " de " + (key+1) }}</h6>
                            <h6 class="text-body" v-if="isFromProposals">{{ installment.installmentNumber + " de " + String(billsInfo.installmentNumber) }}</h6>
                            <h6 class="text-body" v-if="isFromAgreements">{{ (parseInt(installment.installmentNumber) + 1) + " de " + String(agreementInfo.installmentNumber) }}</h6>
                          </div>
                          <div class="pl-0 col-lg-3 col-7">
                            <p class="mb-1 small">Valor:</p>
                            <h6 class="text-body">{{ formatFloatToReal(parseFloat(installment.paymentValue, 10)) }}</h6>
                          </div>
                          <div class="col-lg-3 col-5">
                            <p class="mb-1 small">Vencimento:</p>
                            <h6 class="text-body">{{ formatData(installment.dueDate, "YYYY-MM-DD", "L") }} </h6>
                          </div>
                          <div class="pl-0 col-lg-2 col-5">
                            <p class="small mb-1 ">Status:</p>
                            <h6 class="text-body" v-if="installment.status === 'EmAberto'">Em aberto</h6>
                            <h6 class="text-success" v-if="installment.status === 'Liquidada'">Pago</h6>
                          </div>
                          <div class="text-center p-0 d-flex justify-content-center align-items-end col-lg-1 col-2">
                            <button v-if="installment.status !== 'Liquidada' && installment.digitableLine" @click="expandAndClose(installment)" class="btn btn-link text-muted mt-0" type="button" data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                              <i v-if="contentInstallment !== installment" class="fa fa-chevron-down"></i>
                              <i v-if="contentInstallment === installment" class="fa fa-chevron-up"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div v-bind:class="[ (contentInstallment === installment) ? 'collapse show' : 'collapse' ]" aria-labelledby="heading1" style="">
                        <div class="card-body px-4">
                          <div class="row" v-if="installment.digitableLine || installment.status !== 'Liquidada'">
                            <div class="col-xs-8 col-12 col-lg-12 px-1">
                              <p class="text-info mb-1">Linha digitável:</p><a class="btn btn-link btn-sm float-right mt-2 pl-1 pr-3" href="#" v-if="installment.digitableLine" @click="copiarLinhaDigitavel(installment.digitableLine)">Copiar</a>
                              <p class="codebar">{{ installment.digitableLine }}</p>
                            </div>
                            <div class="col-12 d-flex col-lg-12 flex-row px-0 mt-2 justify-content-around" >
                              <button class="btn btn-link mr-1 mb-1 text-left" :disabled="loadingDownloadEmitirFatura || loading" @click="downloadBoleto(installment)">
                                <i class="fa fa-download fa-fw pull-left pt-1 pb-2 d-none d-sm-block"></i>Baixar boleto
                              </button>
                              <button class="btn mr-1 mb-1 btn-link text-left" :disabled="loadingDownloadEmitirFatura || loading" @click="sendSms(installment.digitableLine)">
                                <i class="fa fa-mobile fa-fw fa-lg pull-left mr-1 pt-1 pb-2 d-none d-sm-block"></i>Receber por SMS
                                </button>
                                <button class="btn mr-1 mb-1 btn-link text-left" :disabled="loadingDownloadEmitirFatura || loading" @click="sendEmail(installment.digitableLine)">
                                  <i class="fa fa-envelope-o fa-fw pull-left pt-1 pb-2 d-none d-sm-block"></i>Receber por e-mail
                                  </button>
                            </div>
                            <div class="col-xs-8 col-12 d-flex flex-column col-lg-12 pb-3 pt-2" style="" v-if="loading">
                              <ProgressLoading v-bind:loading="loading" />
                            </div>
                            <div class="col-xs-8 col-12 d-flex flex-column col-lg-12 pb-3 pt-2" style="" v-if="loadingDownloadEmitirFatura && contentInstallment === installment">
                              <ProgressLoading v-bind:loading="loadingDownloadEmitirFatura"/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal name="newEmail" height="auto" style="margin: auto;">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="eModalLabel">Você não tem nenhum e-mail cadastrado.</h5>
            <button class="close" type="button" data-dismiss="modal" aria-label="Close" @click="fecharModal()">
            <span aria-hidden="true">x</span>
            </button>
        </div>

        <label class="m-4">Por favor, informe um novo e-mail.</label>
        <div class="col-12">
                              <input
                                v-model="newEmail"
                                :disabled="loading"
                                maxlength="50"
                                type="text" class="form-control my-3" placeholder="E-mail"
                                @change="eventFillInputEmailGTM()">
                          </div>

        <div class="modal-footer">
              <button
                v-if="!loading"
                class="btn btn-secondary text-white"
                type="button"
                data-dismiss="modal"
                @click="fecharModal('cancelar')"
              >
                Cancelar
              </button>
              <a v-if="!loading" class="btn btn-primary" href="#" @click="insertNewEmailAndSend()"
                >Cadastrar</a
              >
              <ProgressLoading v-bind:loading="loading" />
            </div>
        </div>

    </modal>
    <modal name="newPhoneNumber" height="auto" style="margin: auto;">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="eModalLabel">Você não tem nenhum telefone cadastrado.</h5>
            <button class="close" type="button" data-dismiss="modal" aria-label="Close" @click="fecharModalNewPhone()">
            <span aria-hidden="true">x</span>
            </button>
        </div>

        <label class="m-4">Por favor, informe um novo número de telefone.</label>
        <div class="col-12">

          <input
                type="tel"
                class="
                  form-control form-control-lg
                  text-center
                  home-form
                  quicks
                  mb-2
                "
                placeholder="Informe o telefone aqui"
                id="form10"
                v-model="newPhoneNumber"
                v-mask="['(##) # ####-####']"
              />
                          </div>

        <div class="modal-footer">
              <button
                v-if="!loading"
                class="btn btn-secondary text-white"
                type="button"
                data-dismiss="modal"
                @click="fecharModalNewPhone()"
              >
                Cancelar
              </button>
              <a v-if="!loading" class="btn btn-primary" href="#" @click="insertNewPhoneNumberAndSend()"
                >Cadastrar</a
              >
              <ProgressLoading v-bind:loading="loading" />
            </div>
        </div>

    </modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
  formatCurrencieValueBRL as formatValue,
  formatData,
} from "@/utils/helpers";
import ProgressLoading from "../../../modules/auth/components/ProgressLoading.vue";
import HeaderOptions from "../components/HeaderMenu.vue";
import { initializeGlobals } from '@/global';

export default {
  name: "meus-boletos",
  components: {
    ProgressLoading,
    HeaderOptions
  },
  data() {
    return {
      loadBoletos: false,
      loadingAgreementsUpdated: false,
      loading: false,
      loadingDownloadEmitirFatura: false,
      hasBills: false,
      isFatura: false,
      contractInfo: null,
      agreementsPst: null,
      agreementPstClosed: null,
      billsInfo: null,
      contract: [],
      agreements: [],
      dadosFatura: [],
      digitableLine: null,
      newEmail: null,
      newPhoneNumber: null,
      agreementsAreUpdated: false,
      agreementsUpdated: [],
      contentInstallment: {},
      installmentsNumber: null,
      isEmitirFatura: false,
      isFromProposals: false,
      isFromAgreements: false,
      isEquals: false,
      pageLocation: null,
      userDataStorage: {},
      url: null,
      protocol: null,
      path: null,
      host: null,
      userAdm: {},
      ipUser: null
    };
  },

  mounted() {
    this.$emit('updatePageView', this.pageLocation);
  },
  async created() {
    this.updateCustomData();
    this.ipUser = localStorage.getItem('ipUser');
    localStorage.setItem("currentPageName", this.$options.name);
    this.userDataStorage = JSON.parse(localStorage.getItem("userData"));
    this.userAdm = JSON.parse(localStorage.getItem("userAdm"));
    this.url = window.location.href;
    this.protocol = window.location.protocol;
    this.host = window.location.host;
    this.path = window.location.pathname;
    this.pageLocation = this.protocol + '//' + this.host + this.path;
    this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.pageLocation, 'meus-boletos');
    
    this.largura = window.screen.width;

    if (this.$route.params.fromEmitirFatura) {
      this.isEmitirFatura = true;
      try {
        this.loadBoletos = true;
        this.agreementInfo = this.$route.params.agreementInfo;
        const result = await this.ActionEmitirFaturaPortoSeguro({
          clientCode: this.agreementInfo.clientCode,
          credor: this.agreementInfo.credor,
          contract: this.agreementInfo.contract,
          tag: localStorage.getItem('tag'),
          phoneNumber: this.user.PhoneNumber != null ? this.user.PhoneNumber : this.user.MobileNumber,
          UserID: this.userAdm ? this.userAdm.UserID : null,
          UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
          OS:navigator.platform,
          IP: this.ipUser ? this.ipUser : '',
        });
        if(!result) {
          this.flashMessage.show({
            time: 3000,
            status: "error",
            title: "Error",
            message: "Não foi possível emitir a fatura",
          });
          this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.protocol+'//'+ this.host+'/dashboard', 'Dashboard');
          this.updateCustomData();
          this.$router.push({
            name: "dashboard",
          });
        } else {
          this.billsInfo = this.buildBillsInfoFromEmitirFatura(result);
          this.contentInstallment = this.billsInfo.installments[0];
        }
        this.loadBoletos = false;
      } catch {
        this.flashMessage.show({
          time: 3000,
          status: "error",
          title: "Error",
          message: "Não foi possível emitir a fatura",
        });
        this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.protocol+'//'+ this.host+'/dashboard', 'Dashboard');
        this.updateCustomData();
        this.$router.push({
          name: "dashboard",
        });
      } finally {
        this.loadBoletos = false;
      }
    }

    else if (this.$route.params.fromPst) {
      try {
        this.billsInfo = this.buildBillsInfoFromPst(this.$route.params.agreementInfo);
        this.contentInstallment = this.billsInfo.installments[0];
        return
      } catch {
        this.flashMessage.show({
          time: 3000,
          status: "error",
          title: "Error",
          message: "Não foi possível emitir a fatura",
        });
        this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.protocol+'//'+ this.host+'/dashboard', 'Dashboard');
        this.updateCustomData();
        this.$router.push({
          name: "dashboard",
        });
      } finally {
        this.loadBoletos = false;
      }
    }

    else if (this.$route.params.fromProposals) {
      this.agreementInfo = this.$route.params.agreementInfo;
      this.billsInfo = this.buildBillsInfoFromParcFat(this.$route.params.agreementInfo)
      this.contentInstallment = this.billsInfo.installments[0];
      this.isFromProposals = true;

    }

    else if (this.$route.params.fromAgreements) {
      this.isFromAgreements = true;


      this.loadBoletos = true;
      try {

        this.agreementInfo = this.$route.params.agreementInfo;
        const responseFromAgreementDetails = await this.ActionBoletosLinhaDigitavelPortoSeguro({
          clientCode: this.agreementInfo.clientCode,
          credor: this.agreementInfo.credor,
          installmentCode: this.agreementInfo.installmentCode,
          tag: localStorage.getItem('tag'),
          UserID: this.userAdm ? this.userAdm.UserID : null,
          UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
          OS:navigator.platform,
          IP: this.ipUser ? this.ipUser : '',
        })
        this.billsInfo = this.buildBillsInfoFromAgreements(responseFromAgreementDetails);
        this.contentInstallment = this.billsInfo.installments[0];
      } catch {
        this.flashMessage.show({
          time: 3000,
          status: "error",
          title: "Error",
          message: "Não foi possível exibir os boletos do acordo",
        });
        this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.protocol+'//'+ this.host+'/dashboard', 'Dashboard');
        this.updateCustomData();
        this.$router.push({
          name: "dashboard",
        });
      } finally {
        this.loadBoletos = false;
      }
    }
  },
  computed: {
    ...mapState("auth", ["user"]),
    isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
  },
  methods: {
    formatValue,
    formatData,

    isObjectEmpty(obj) {
      return Object.keys(obj).length === 0;
    },

    expandAndClose(installment) {
      var isEmpyt = this.isObjectEmpty(this.contentInstallment);

      if(!isEmpyt && this.contentInstallment === installment) {

        this.contentInstallment = {};
      } else {
        this.contentInstallment = installment;
      }
    },


    buildBillsInfoFromAgreements(agreementDetails) {
      const installmentNumber = agreementDetails.installments.length
      return { ...this.agreementInfo, ...agreementDetails, installmentNumber }
    },

    buildBillsInfoFromParcFat(agreementInfo) {
      return {
        ...agreementInfo,
        installmentNumber: parseInt(agreementInfo.installmentNumber)
      }
    },

    buildBillsInfoFromPst(agreementInfo) {
      return {
        ...agreementInfo,
        installmentNumber: parseInt(agreementInfo.installmentNumber) + 1
      }
    },

    buildBillsInfoFromEmitirFatura(result) {
      return {
        ...this.agreementInfo,
        ...result,
        installmentNumber: 1
      }
    },

    formatFloatToReal(value) {
      return value.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
    },

    home() {
      this.eventClickToBackGTM();
      this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.protocol+'//'+ this.host+'/dashboard', 'Dashboard');
      this.updateCustomData();
      
      this.$router.push({
        name: "dashboard",
      });
    },

    ...mapActions("meusContratos", ["ActionEmitirFaturaPortoSeguro", "ActionBoletoPortoSeguro", "ActionBoletosLinhaDigitavelPortoSeguro"]),
    ...mapActions("meusBoletos", ["ActionSendEmailOrSmsText"]),

    async sendSms(LinhaDigitavel) {
      this.eventInteractElementsGTM('receber-por-sms');
      this.loading = true;
        if(this.user.PhoneNumber === '' || this.user.PhoneNumber === null &&  this.user.MobileNumber === '' || this.user.MobileNumber === null) {
          this.loading = false;
          this.linhaDigitavel = LinhaDigitavel;
          this.abrirModalNewPhone();

          return;
        }
      try {
        await this.ActionSendEmailOrSmsText({
          LinhaDigitavel,
          Email: '',
          PhoneNumber: this.user.PhoneNumber != null ? this.user.PhoneNumber : this.user.MobileNumber,
          type: 'sms',
          httpOwnerId: this.ownerId,
          UserID: this.userAdm ? this.userAdm.UserID : null,
          UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
          OS:navigator.platform,
          IP: this.ipUser ? this.ipUser : '',
          clientCode: this.billsInfo.clientCode,
          credor: this.billsInfo.credor,
          contract: this.billsInfo.contract,
        });

        this.flashMessage.show({
          timeout:1000,
          blockClass: 'custom-block-class',
          status: 'success',
          title: 'Sucesso',
          message: 'Linha digitável enviado por sms com sucesso'});
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },

    async sendEmail(LinhaDigitavel) {
          this.eventInteractElementsGTM('receber-por-email');
          this.loading = true;
          if(this.user.Email !== '' || this.user.Email !== null) {
            this.loading = false;
            this.linhaDigitavel = LinhaDigitavel;
            this.eventModalViewGTM();
            this.abrirModal();
            return;
          }

          try {
            var resp = await this.ActionSendEmailOrSmsText({
              LinhaDigitavel,
              Email: this.user.Email,
              PhoneNumber: '',
              type: 'email',
              httpOwnerId: this.ownerId,
              UserID: this.userAdm ? this.userAdm.UserID : null,
              UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
              OS:navigator.platform,
              clientCode: this.billsInfo.clientCode,
              credor: this.billsInfo.credor,
              contract: this.billsInfo.contract,
            });

            if(resp.data.code !== 200) {
              this.flashMessage.show({
                time: 3000,
                status: "error",
                title: "Error",
                message: "Não foi possível fazer o envio por e-mail",
              });
              this.loading = false;
              return;
            } else {
              this.loading = false;
              this.flashMessage.show({
                time:3000,
                blockClass: 'custom-block-class',
                status: 'success',
                title: 'Sucesso',
                message: 'Linha digitável enviado por e-mail com sucesso'});

              }
              this.loading = false;

          } catch {
            this.loading = false;
          }
        },

        validateEmail(email) {
          var re = /\S+@\S+\.\S+/;
          return re.test(email);
        },

        async insertNewPhoneNumberAndSend() {
          this.loading = true;

          if(this.newPhoneNumber === '' || this.newPhoneNumber === null) {
            this.flashMessage.show({
                timeout: 1000,
                status: "error",
                title: "Error",
                message: "Informe um telefone!",
              });
              this.loading = false;
            return;
          }

          try {
            var resp = await this.ActionSendEmailOrSmsText({
              LinhaDigitavel: this.linhaDigitavel,
              Email: '',
              PhoneNumber: this.newPhoneNumber.replace("(", "")
                    .replace(")", "")
                    .replace(" ", "")
                    .replace(" ", "")
                    .replace("-", ""),
              type: 'sms',
              httpOwnerId: this.ownerId,
              UserID: this.userAdm ? this.userAdm.UserID : null,
              UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
              OS:navigator.platform,
              clientCode: this.billsInfo.clientCode,
              credor: this.billsInfo.credor,
              contract: this.billsInfo.contract,
            });


            if(resp.data.code !== 200) {
              this.flashMessage.show({
                timeout: 1000,
                status: "error",
                title: "Error",
                message: "Não foi possível fazer o envio por sms",
              });
              this.loading = false;
              return;
            } else {
              this.flashMessage.show({
                timeout:1000,
                blockClass: 'custom-block-class',
                status: 'success',
                title: 'Sucesso',
                message: 'Linha digitável enviado por sms com sucesso'});
                this.loading = false;
                this.fecharModalNewPhone();
              }
          } catch {
            this.loading = false;
          }
        },

        async insertNewEmailAndSend() {
          this.eventClickToCadastrarOrCancelEmailGTM('cadastrar');
          this.loading = true;


          if(this.newEmail === '' || this.newEmail === null) {
            const pageName = this.$options.name;
            const eventCategory = `portal-de-negociacao:porto:${pageName}`;
            const eventAction = 'cadastrar-email:alert';
            const eventLabel = 'modal:Não foi possível fazer o envio por e-mail';
            const eventCode = 401;
            const eventErrorService = '';
            const eventAlertService = "Informe um e-mail!";
            const eventAlert = 'self-service';
            const eventAlertType = 'erro';
            const eventData = {
              event: 'alert',
              ev_category: eventCategory,
              ev_action: eventAction,
              ev_label: eventLabel,
              alert_code: eventCode,
              error_service: eventErrorService,
              alert_service_message: eventAlertService,
              alert_event: eventAlert,
              alert_type: eventAlertType,
            };

            window.dataLayer.push({ event: 'alert', ...eventData });

            this.flashMessage.show({
                timeout: 1000,
                status: "error",
                title: "Error",
                message: "Informe um e-mail!",
              });
              this.loading = false;
            return;
          }

          var email = this.validateEmail(this.newEmail);
          if (!email) {
            const pageName = this.$options.name;
            const eventCategory = `portal-de-negociacao:porto:${pageName}`;
            const eventAction = 'cadastrar-email:alert';
            const eventLabel = 'modal:Não foi possível fazer o envio por e-mail';
            const eventCode = 401;
            const eventErrorService = '';
            const eventAlertService = "Informe um e-mail válido!";
            const eventAlert = 'self-service';
            const eventAlertType = 'erro';
            const eventData = {
              event: 'alert',
              ev_category: eventCategory,
              ev_action: eventAction,
              ev_label: eventLabel,
              alert_code: eventCode,
              error_service: eventErrorService,
              alert_service_message: eventAlertService,
              alert_event: eventAlert,
              alert_type: eventAlertType,
            };

            window.dataLayer.push({ event: 'alert', ...eventData });

            this.flashMessage.show({
              timeout: 1000,
              status: "error",
              title: "Ocorreu um erro",
              message: "Informe um email válido!",
            });
            this.loading = false;
            return;
          }

          try {
            var resp = await this.ActionSendEmailOrSmsText({
              LinhaDigitavel: this.linhaDigitavel,
              Email: this.newEmail,
              PhoneNumber: '',
              type: 'email',
              httpOwnerId: this.ownerId,
              UserID: this.userAdm ? this.userAdm.UserID : null,
              UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
              OS:navigator.platform,
              clientCode: this.billsInfo.clientCode,
              credor: this.billsInfo.credor,
              contract: this.billsInfo.contract,
            });


            if(resp.data.code !== 200) {
              this.flashMessage.show({
                timeout: 1000,
                status: "error",
                title: "Error",
                message: "Não foi possível fazer o envio por e-mail",
              });
              this.eventCallbackCadastrarFailureGTM(resp);
              this.loading = false;
              return;
            } else {
              this.flashMessage.show({
                timeout:1000,
                blockClass: 'custom-block-class',
                status: 'success',
                title: 'Sucesso',
                message: 'Linha digitável enviado por e-mail com sucesso'});
                this.loading = false;
                this.eventCallbackSuccessCadastrarGTM(resp);
                this.fecharModal();
              }
          } catch {
            this.loading = false;
          }
        },


    async downloadBoleto(installment) {
      this.eventInteractElementsGTM('baixar-boleto');
      this.loadingDownloadEmitirFatura = true;
      this.contentInstallment = installment;

      // Se estiver vencido, passa D + 1
      const installmentDueDate = installment.dueDate
      const today = new Date()
      const flagDate = today > new Date(installmentDueDate)

      try {
        let result = await this.ActionBoletoPortoSeguro({
          clientCode: this.billsInfo.clientCode,
          credor: this.billsInfo.credor,
          installmentCode: this.billsInfo.installmentCode,
          installmentDueDate,
          boletoDueDate: flagDate ? today.toJSON().split('T')[0] : installmentDueDate,
          tag: localStorage.getItem('tag'),
          UserID: this.userAdm ? this.userAdm.UserID : null,
          UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
          OS:navigator.platform,
          IP: this.ipUser ? this.ipUser : '',
        });

        const linkSource = `data:application/pdf;base64,${result.base64}`;
        const downloadLink = document.createElement("a");
        const fileName = "boleto.pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        this.flashMessage.show({
          time: 3000,
          status: "success",
          title: "Sucesso",
          blockClass: "custom-block-class",
          message: "Download do boleto realizado com sucesso",
        });
      } catch {
        this.flashMessage.show({
          time: 3000,
          status: "error",
          title: "Erro",
          blockClass: "custom-block-class",
          message:
            "Ocorreu um erro ao tentar fazer o download do boleto, tente novamente mais tarde",
        });
      } finally {
        this.loadingDownloadEmitirFatura = false;
      }
    },

    fecharModal(action){
          if(action) {
            this.eventClickToCadastrarOrCancelEmailGTM(action);
          }
          this.$modal.hide('newEmail');
    },
    abrirModal(){
      this.$modal.show('newEmail');
    },
    fecharModalNewPhone(){
      this.$modal.hide('newPhoneNumber');
    },
    abrirModalNewPhone(){
      this.$modal.show('newPhoneNumber');
    },

   async copiarLinhaDigitavel(linhaDigitavel) {
    this.eventInteractElementsGTM('copiar-linha-digitavel');
      const elem = document.createElement("textarea");
      elem.value = linhaDigitavel;
      document.body.appendChild(elem);
      elem.select();
      document.execCommand("copy");
      document.body.removeChild(elem);

      this.flashMessage.show({
        timeout: 1000,
        status: "success",
        title: "Sucesso",
        blockClass: "custom-block-class",
        message: "Linha digitável foi copiada para o seu clipboard",
      });
    },

    async gerarLinhaDigitavel(value) {
      var result = await this.ActionGerarLinhaDigitavelPortoSeguro({
        clientCode: value.clientCode,
        credor: value.credor,
        installmentCode: value.installmentCode,
        tag: localStorage.getItem('tag'),
        UserID: this.userAdm ? this.userAdm.UserID : null,
        UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
        OS:navigator.platform
      })

      if(result) {
        this.hasBills = false;
        this.agreementsAreUpdated = true;
        this.agreementsUpdated = result;
      }
    },

    buildAndSetUserData(doc, id, pageLocation, pageName) {
      // const pageName = this.$options.name;
      const userData = {
                      cpfCnpj: doc, // Substitua pelo CPF/CNPJ real
                      id: id, // Substitua pelo ID real
                      page_location: pageLocation,
                      pageName: pageName
                    };
                    localStorage.setItem("userData", JSON.stringify(userData));
    },

    eventClickToBackGTM() {
          this.pageName = localStorage.getItem('currentPageName') === null ? 'dashboard' : localStorage.getItem('currentPageName');
          const eventCategory = `portal-de-negociacao:porto:${this.pageName}`;
          const eventAction = 'click:button:voltar';

          const eventData = {
            event: 'select_content',
            ev_category: eventCategory,
            ev_action: eventAction,
            ev_label: '',
            product_identify: this.agreementInfo.contract,
            product_category: this.agreementInfo.product,
            product_user_profile: 'titular',
            brand: 'porto',
            product: this.agreementInfo.product,
            vertical: 'bank',
          };

          console.log("eventData ClickToBack MEUS BOLETOS: " + JSON.stringify(eventData))

          window.dataLayer.push({ event: 'select_content', ...eventData });
        },


        eventInteractElementsGTM(action) {
          this.pageName = localStorage.getItem('currentPageName') === null ? 'dashboard' : localStorage.getItem('currentPageName');
          const eventCategory = `portal-de-negociacao:porto:${this.pageName}`;
          const eventAction = `click:button:${action}`;

          const eventData = {
            event: 'select_content',
            ev_category: eventCategory,
            ev_action: eventAction,
            ev_label: '',
            product_identify: this.agreementInfo.contract,
            product_category: this.agreementInfo.product,
            product_user_profile: 'titular',
            brand: 'porto',
            product: this.agreementInfo.product,
            vertical: 'bank',
          };

          console.log("eventData Interaction MEUS BOLETOS: " + JSON.stringify(eventData))

          window.dataLayer.push({ event: 'select_content', ...eventData });

        },

        eventModalViewGTM() {
          this.pageName = localStorage.getItem('currentPageName') === null ? 'dashboard' : localStorage.getItem('currentPageName');
          const eventCategory = `portal-de-negociacao:porto:${this.pageName}`;
          const eventAction = 'dialog:visualizar';

          const eventData = {
            event: 'dialog',
            ev_category: eventCategory,
            ev_action: eventAction,
            ev_label: 'modal:voce-nao-tem-nenhum-email-cadastrado',
          };

          console.log('eventData modal View MEUS BOLETOS: ' + JSON.stringify(eventData));

          window.dataLayer.push({ event: 'dialog', ...eventData });

        },

        eventFillInputEmailGTM() {
          const pageName = this.$options.name;
          const eventCategory = `portal-de-negociacao:porto:${pageName}`;
          const eventAction = 'preencher:input:email';
          const eventData = {
            event: 'select_content',
            ev_category: eventCategory,
            ev_action: eventAction,
            ev_label: 'modal:voce-nao-tem-nenhum-email-cadastrado',
          };

          console.log("eventData eventFillInputEmailGTM Meus BOLETOS: " + JSON.stringify(eventData))

          window.dataLayer.push({ event: 'select_content', ...eventData });
            
        },

        eventClickToCadastrarOrCancelEmailGTM(action) {

          const pageName = this.$options.name;
          const eventCategory = `portal-de-negociacao:porto:${pageName}`;
          const eventAction = `click:button:${action}`;

          const eventData = {
            event: 'select_content',
            ev_category: eventCategory,
            ev_action: eventAction,
            ev_label: 'modal:voce-nao-tem-nenhum-email-cadastrado',
          };

          console.log('eventData click CadastrarOrCancel: ' + JSON.stringify(eventData));

          window.dataLayer.push({ event: 'select_content', ...eventData });

        },

        eventCallbackSuccessCadastrarGTM(callback) {
          const pageName = this.$options.name;
          const eventCategory = `portal-de-negociacao:porto:${pageName}`;
          const eventAction ='cadastrar-email:sucesso';

          const eventData = {
            event: 'self_service',
            ev_category: eventCategory,
            ev_action: eventAction,
            ev_label: 'modal:Cadastro feito com sucesso /' + callback.data.message,
            service_protocol: '',
            service_detail: 'Email cadastrado e envio da linha digitável',
            service_type: 'solicitacao',
          };

          console.log("eventData Cadastrar SUCESSO: " + JSON.stringify(eventData))

          window.dataLayer.push({ event: 'self_service', ...eventData });
        },

        eventCallbackCadastrarFailureGTM(error) {
                    const pageName = this.$options.name;
                    const eventCategory = `portal-de-negociacao:porto:${pageName}`;
                    const eventAction = 'cadastrar-email:alert';
                    const eventLabel = 'modal:Não foi possível fazer o envio por e-mail';
                    const eventCode = error.status;
                    const eventErrorService = '';
                    const eventAlertService = error.data.message;
                    const eventAlert = 'self-service';
                    const eventAlertType = 'erro';
                    const eventData = {
                      event: 'alert',
                      ev_category: eventCategory,
                      ev_action: eventAction,
                      ev_label: eventLabel,
                      alert_code: eventCode,
                      error_service: eventErrorService,
                      alert_service_message: eventAlertService,
                      alert_event: eventAlert,
                      alert_type: eventAlertType,
                    };

                    console.log("eventData Cadastrar ERRO: " + JSON.stringify(eventData))

                    window.dataLayer.push({ event: 'alert', ...eventData });
    },

    updateCustomData() {
      const globals = initializeGlobals();
      window.customData = globals.customData;
    }

  },
};
</script>
<style scoped>

</style>
